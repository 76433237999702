import React from 'react';
import 'devicon/devicon.css';

const skills = [
  { label: 'JavaScript', class: 'devicon-javascript-plain' },
  { label: 'Node.js', class: 'devicon-nodejs-plain' },
  { label: 'Vue.js', class: 'devicon-vuejs-plain' },
  { label: 'React', class: 'devicon-react-plain' },
  { label: 'Docker', class: 'devicon-docker-plain' },
  { label: 'MongoDB', class: 'devicon-mongodb-plain' }
];

export default class Skills extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLabelVisible: false, labelValue: '' };
  }

  onIconEnter(label) {
    this.setState({ isLabelVisible: true, labelValue: label });
  }

  onIconLeave() {
    this.setState({ isLabelVisible: false });
  }

  render() {
    return (
      <div className="skills">
        <div>
          {skills.map(s => (
            <i
              onMouseEnter={() => this.onIconEnter(s.label)}
              onMouseLeave={() => this.onIconLeave()}
              key={s.label}
              className={s.class}
            ></i>
          ))}
        </div>
        <div className={`label ${this.state.isLabelVisible ? 'visible' : ''}`}>
          {this.state.labelValue}
        </div>
      </div>
    );
  }
}
