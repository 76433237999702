import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Skills from '../components/skills';

const IndexPage = () => (
  <Layout>
    <SEO />
    <main>
      <h1>Loann Neveu</h1>
      <h3>Fullstack JavaScript developer — Rennes (France)</h3>
      <Skills></Skills>
    </main>
    <nav>
      <a href="https://github.com/lneveu">Github</a>
      <a href="https://www.linkedin.com/in/loann-neveu-52bba2104/">Linkedin</a>
      <a href="mailto:contact@loann-neveu.fr">email</a>
    </nav>
  </Layout>
);

export default IndexPage;
